.anim-updown-slow {
    -webkit-animation: anim-updown-slow 25s linear infinite both;
    animation: anim-updown-slow 25s linear infinite both;
}

/**
 * ----------------------------------------
 * animation anim-updown-slow
 * ----------------------------------------
 */
@-webkit-keyframes anim-updown-slow {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px);
    }

    90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px);
    }
}

@keyframes anim-updown-slow {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px);
    }

    90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px);
    }
}


@keyframes flame-animation {

    0%,
    100% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0.75) rotate(0) scale(1);
    }

    25% {
        opacity: 0.35;
        transform: translate3d(0, -10%, 0) scale(1) rotate(-3deg) scale(1.05);
    }

    50% {
        opacity: 0.35;
        transform: translate3d(0, -4%, 0) scale(1) rotate(3deg) scale(1.1);
    }

    75% {
        opacity: 0.35;
        transform: translate3d(0, -20%, 0) scale(1) rotate(-3deg) scale(1.05);
    }

    99% {
        opacity: 0;
        transform: translate3d(0, -50%, 0) scale(0.8) rotate(0) scale(1);
    }
}

/* .fire {
} */

.flame {
    position: absolute;
    transform-origin: 70% 70%;
    z-index: 2;
    display: inline-block;
}

.flame.animate {
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-name: flame-animation;
    opacity: 0;
    z-index: 1;
    transform: translate3d(0, 15px, 0) scale(0.75) rotate(0);
    z-index: 1;
    animation-timing-function: ease-in;
}

.flame.animate:nth-child(2) {
    animation-delay: 0.5s;
}

.flame.animate:nth-child(3) {
    animation-delay: 1s;
}

.bg-pan-bl {
    -webkit-animation: bg-pan-bl 500s linear both;
    animation: bg-pan-bl 500s linear both
}

@-webkit-keyframes bg-pan-bl {
    0% {
        background-position: 100% 0
    }

    100% {
        background-position: 0 100%
    }
}

@keyframes bg-pan-bl {
    0% {
        background-position: 100% 0
    }

    100% {
        background-position: 0 100%
    }
}


.scale-in-center {
	-webkit-animation: scale-in-center 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}@keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}

.fade-in{-webkit-animation:fade-in 1.2s cubic-bezier(.39,.575,.565,1.000) both;animation:fade-in 1.2s cubic-bezier(.39,.575,.565,1.000) both}
@-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}