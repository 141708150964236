.card {
    /* box-shadow: 2px 2px 3px var(--grey); */
    box-shadow: 0px 1px 7px rgb(109 102 102 / 20%);
    background-color: var(--white);
    border-radius: 15px;
    min-height: 225px;
    border: none;
}


.insta-center {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    aspect-ratio: 9 / 16;

    justify-content: center;
    align-items: center;

}
.insta-center .card-img-top{
    /* padding-top: 50%; */
    width: 100px !important;
    height: auto
}