.round-cur-logo {
    width: 5rem;
    height: auto;
    margin: auto;
    padding: 25px 0px 0px 0px;
}
.navbar{
     background: transparent !important;
}

.cur-search-box{
    background-color: var(--white);
    width: 80%;
    height: 50px;
    border-radius: 200px;
    position: relative;
}
.cur-search-icon{
    position: absolute;
    color: var(--grey);
    bottom: 11px;
    font-size: 20px;
    width: 40px;
}
.cur-search-input{
    background: transparent;
    border: none;
    height: inherit;
    padding-left: 40px;

    opacity: 0.6;
    transition: opacity 300ms;

    font-family: var(--font-paragraph);
    font-size: large;
}
.cur-search-input:focus-visible{
    opacity: 1;
    outline: none;
    transition: opacity 300ms;
}
.cur-search-button{
    height: 101%;
    border: none;
    position: absolute;
    background: transparent;

    right: -25px;
    top: -2px;

    width: 61px;
}
.cur-search-button img{
    height: 105%;
    width: auto;
    background-color: var(--primary-blue);
    border-radius: 200px;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-nav {
    padding-right: 1rem;
}
.navbar-nav > li {
    margin-right: 1rem;
}
#navbarSupportedContent{
    place-content: flex-end;
}
.nav-container {
    background-color: rgba(255,255,255, 0.5);
    backdrop-filter: blur(10px);
}