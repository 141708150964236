@media only screen and (max-width: 767px) {
    .remove-on-mobile {
      visibility: hidden;
      width: 0px;
      height: 0px;
      display:none;
    }
}
@media only screen and (max-width: 767px) {
    .full-width-on-mobile {
      width: 100% !important;
    }
}